import NextLink from 'next/link';
import { Inter } from 'next/font/google'

const inter = Inter({ subsets: ['latin'] })

export default function FaqBlock(props) {
    return (
        <div class="w-full space-y-5">
        {!props.title ? null :
        <>
            <h2 class="text-3xl font-bold text-gray-800 font-title sm:text-4xl">Frequently Asked Questions</h2> 
            <p class="mt-4 text-base font-normal text-gray-500 dark:text-gray-700">Answers to common questions about our personalized beauty service for individual consumers.</p>        
        </>}
        <div className="collapse collapse-plus border-b border-gray-200 h-min">
  <input type="radio" name="my-accordion-1" /> 
            <div className="collapse-title text-left text-base font-bold text-gray-900">
            How does the AI Face Shape Analyzer work?
            </div>
            <div className="collapse-content text-gray-700"> 
                <p>The AI Face Shape Analyzer, and other facial analysis tools on StyleSense, uses a facial landmark detection algorithm, and then uses the proportions, angles, and ratios between different points on the face to classify the face shape in the uploaded picture. The Face Shape Analyzer will classify the uploaded image as one or two of the shapes that most closely match out of the available options — Oval, Round, Square, Rectangle, Pear, Heart and Diamond. <NextLink prefetch={false} href="/blog/understanding-different-face-shapes">Read about the different face shapes <span className={`${inter.className}`}>-&gt;</span></NextLink></p>
            </div>
        </div>  
        <div className="collapse collapse-plus border-b border-gray-200 h-min">
  <input type="radio" name="my-accordion-1" /> 
            <div className="collapse-title text-left text-base font-bold text-gray-900">
            How accurate is the AI Face Shape Analyzer?
            </div>
            <div className="collapse-content text-gray-700"> 
                <p>Internal testing has yielded a 92% accuracy rate for our Face Shape Analyzer. However, it&apos;s important to note that face shape analysis is not always precise due to variations in photo quality, facial expressions, and lighting conditions. Additionally, it is common for people to have attributes of more than one face shape, and may not fit neatly into the seven ascribed options. For this reason, our model classifies up to two shapes if it cannot find a clear match given the uploaded picture.</p>
            </div>
        </div>                      
        <div className="collapse collapse-plus border-b border-gray-200 h-min">
  <input type="radio" name="my-accordion-1" /> 
            <div className="collapse-title text-left text-base font-bold text-gray-900">
            Which features are free, and which cost money?
            </div>
            <div className="collapse-content text-gray-700"> 
                <p>Our free features include the AI Face Shape Analyzer, and the Live Face Contour Guides. Credits are available for purchase at $1 each, and can be used towards our Advanced Features, including the Eye Shape Analyzer and Live Nose Contour Guides. <NextLink prefetch={false} className="text-blue-500 link link-hover" href="https://www.reddit.com/r/stylesense/comments/154uocq/about_the_new_eye_shape_analyzer_and_upcoming/">Read more <span className={`${inter.className}`}>-&gt;</span></NextLink></p>
            </div>
        </div>     
        <div className="collapse collapse-plus border-b border-gray-200 h-min">
  <input type="radio" name="my-accordion-1" /> 
            <div className="collapse-title text-left text-base font-bold text-gray-900">
            Will the AI Face Shape Analyzer always be free?
            </div>
            <div className="collapse-content text-gray-700"> 
                <p>Yes. We recognize that there is a gap in the market and are committed to keeping our Face Shape Analyzer high-quality and free to all, forever.</p>
            </div>
        </div>     
    <div className="collapse collapse-plus border-b border-gray-200 h-min">
        <input type="radio" name="my-accordion-1" /> 
        <div className="collapse-title text-left text-base font-bold text-gray-900">
            What is a credit?
        </div>
        <div className="collapse-content text-gray-700"> 
            <p>Credits enable you to use Advanced Features, like the Eye Shape Analyzer and Nose Contour Guide. We are actively developing more Advanced Features, including a Blush Placement Guide, Color Analysis tool and more. Subscriptions include an unlimited budget of credits that you can use within that month. Pay-as-you-go credits will not expire after the purchase.</p>
        </div>
    </div>   
    <div className="collapse collapse-plus border-b border-gray-200 h-min">
        <input type="radio" name="my-accordion-1" /> 
        <div className="collapse-title text-left text-base font-bold text-gray-900">
            Should I purchase credits or a subscription?
        </div>
        <div className="collapse-content text-gray-700"> 
            <p>If you&apos;re just trying the product or have a one-off need, pay as you go credits are a great option. However, for users who wish to incorporate StyleSense into their daily makeup routine, you&apos;ll save with a subscription. Subscriptions are billed monthly and can be canceled at any time. </p>
        </div>
    </div>   
    <div className="collapse collapse-plus border-b border-gray-200 h-min">
        <input type="radio" name="my-accordion-1" /> 
        <div className="collapse-title text-left text-base font-bold text-gray-900">
            How to cancel my subscription?
        </div>
        <div className="collapse-content text-gray-700"> 
            <p> You can cancel your subscription at any time by clicking &quot;Manage Your Plan&quot; in your profile. We partner with Stripe for billing, and all cancellation or refund requests will be handled by the Stripe customer portal. </p>
        </div>
    </div>  
                                                                               
        <div className="collapse collapse-plus border-b border-gray-200 h-min">
  <input type="radio" name="my-accordion-1" /> 
            <div className="collapse-title text-left text-base font-bold text-gray-900">
            What type of pictures should I use to get the best results?
            </div>
            <div className="collapse-content text-gray-700"> 
                <p>To get the best results from our AI tools, you should use front facing photos of a single face with a neutral expression and good lighting. Additionally, you should ensure that the subject&apos;s face is not covered by accessories like sunglasses, hats, or scarves. </p>
            </div>
        </div>                      
        <div className="collapse collapse-plus border-b border-gray-200 h-min">
  <input type="radio" name="my-accordion-1" /> 
            <div className="collapse-title text-left text-base font-bold text-gray-900">
            What happens to my pictures after I upload them?
            </div>
            <div className="collapse-content text-gray-700"> 
                <p>We don&apos;t store your uploaded files on a server nor do we save them to an external database. After you close the browser or refresh the page, the uploaded file(s) are cleared from the browser&apos;s memory. <NextLink prefetch={false} className="text-blue-500 link link-hover" href="/privacy">Read our privacy policy <span className={`${inter.className}`}>-&gt;</span></NextLink></p>
            </div>
        </div>  
        <div className="collapse collapse-plus border-b border-gray-200 h-min">
  <input type="radio" name="my-accordion-1" /> 
            <div className="collapse-title text-left text-base font-bold text-gray-900">
            Is payment secure?
            </div>
            <div className="collapse-content text-gray-700"> 
                <p>Yes, we use Stripe for payment. We do not store any of your credit card information.</p>
            </div>
        </div>             
        {/* <div className="collapse collapse-plus border-b border-gray-200 h-min">
  <input type="radio" name="my-accordion-1" /> 
            <div className="collapse-title text-left text-base font-bold text-gray-900">
            Can I get a refund?
            </div>
            <div className="collapse-content text-gray-700"> 
                <p>tabIndex={0} attribute is necessary to make the div focusable</p>
            </div>
        </div>                                                    */}
                                                                 
</div>  
    )
}