import Head from 'next/head'
import Image from 'next/image'
import { Inter } from 'next/font/google'
import styles from '../styles/Home.module.css'
// import Navbar from '../../components/navbar';
// import Footer from '../../components/footer';
import FaqBlock from '../../components/faqblock';
import NextLink from 'next/link';
import { useEffect } from 'react';
import dynamic from 'next/dynamic'
import HeroGraphicLight from '../../public/hero_graphic_light.png'
import HeroGraphicDark from '../../public/hero_graphic_dark.png'
// import { UserContext } from './_app';
const inter = Inter({ subsets: ['latin'] })

export default function Home() {
  // const { userData, setUserData } = useContext(UserContext);
  const Navbar = dynamic(() => import('../../components/navbar'), { 
    loading: () => <div className="opacity-5 h-16 p-2 w-full bg-slate-200 animate-pulse rounded"></div>,
  }) 
  const Footer = dynamic(() => import('../../components/footer'), {
    loading: () => <div className="w-full bg-slate-200 animate-pulse rounded"></div>,
  })

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      //browser alert
      alert("Order placed! You will receive an email confirmation.");
    }

    if (query.get('canceled')) {
      alert("Order canceled -- continue to browse around and buy credits when you're ready.");
    }
  }, []);  

  return (
    <>
    <Head>
      <title>StyleSense.io - AI Face Analyzer & Personalized Makeup Guidance</title>
      <link
          rel="canonical"
          href="https://stylesense.io"
          key="canonical"
        />      
      <meta
        name="description"
        content="Use our AI tools to identify your face shape, eye shape, and more. Simply take or upload a selfie to get started. Then, use our makeup guides tailored to your facial features to learn how to best accentuate your beauty."
        key="desc"
      />
    </Head>        
    <main className="min-h-screen max-w-full overflow-x-hidden box-border flex flex-col justify-between items-center">
      <Navbar />
      <section className="md:px-8 2xl:p-0 hidden md:block w-full mx-auto">
        <div className="bg-white dark:bg-white/50 p-4 mt-4 rounded-lg flex flex-col md:flex-row gap-8 max-w-7xl mx-auto px-4 lg:px-8">
          <div className="w-full md:w-3/5 flex justify-center flex-col">
            <h1 className="text-4xl font-bold tracking-tight text-black sm:text-5xl max-w-2xl mt-4">
              Say hello to your new{" "}
              <span className="text-transparent bg-clip-text bg-rainbow dark:bg-rainbowdark">
                signature style
              </span>
              .
            </h1>
            <h2 className="font-medium text-xl mt-4 text-gray-700">
              The #1 AI-powered personal stylist and makeup guru
            </h2>
            <p className="hidden md:block max-w-3xl mt-4 text-base font-normal text-black/70 sm:text-lg xl:text-xl">
              Understanding your face shape and facial features is key to
              enhancing your natural beauty. Use our tools to discover the
              makeup techniques and styles that flatter your unique features.
            </p>
            <div className="items-center">
              <NextLink
                href="/face_analyzer"
                className={`mt-6 w-fit mb-4 mt-2 btn rounded-full border-white bg-rainbow hover:bg-hoverrainbow dark:bg-rainbowdark dark:hover:bg-hoverrainbowdark text-white px-4 mr-3`}
              >
                Analyze My Face{" "}
                <span className={`${inter.className} ml-2`}>-&gt;</span>
              </NextLink>
              <NextLink
                href="/makeup_recommendations/face_contour"
                className={`mt-6 w-fit mb-4 mt-2 btn rounded-full border-white bg-rainbowdark hover:bg-hoverrainbowdark dark:bg-rainbow dark:hover:bg-hoverrainbow text-white px-4`}
              >
                Personalize My Makeup{" "}
                <span className={`${inter.className} ml-2`}>-&gt;</span>
              </NextLink>
              <section class="py-2 mt-5">
                <div class="">
                  <div class="flex flex-col items-left justify-left gap-8 md:flex-row lg:gap-16">
                    <div>
                      <h3 class="text-lg font-bold text-black">
                        Free features include
                      </h3>{" "}
                      <ul class="mt-4 space-y-3 text-black">
                        <li class="flex items-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-5 w-5 text-emerald-600"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                          <NextLink href="/face_analyzer">AI Face Shape Analyzer</NextLink>
                        </li>{" "}
                        <li class="flex items-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-5 w-5 text-emerald-600"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                          <NextLink href="/makeup_recommendations/face_contour">Live Face Contour Guides</NextLink>
                        </li>{" "}
                        <li class="flex items-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-5 w-5 text-emerald-600"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                          <NextLink href="/makeup_recommendations/eyeliner">Live Eyeliner Guides</NextLink>
                        </li>
                      </ul>
                    </div>{" "}
                    <div>
                      <h3 class="text-lg font-bold text-black">
                        Advanced features include
                      </h3>{" "}
                      <ul class="mt-4 space-y-3 text-black">
                        <li class="flex items-center gap-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24" className="w-5 h-5 mr-2 text-amber-500 shrink-0"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z"/></svg>
                          <NextLink href="/face_analyzer/eye_shape">AI Eye Shape Analyzer</NextLink>
                        </li>{" "}
                        <li class="flex items-center gap-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24" className="w-5 h-5 mr-2 text-amber-500 shrink-0"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z"/></svg>
                          <NextLink href="/makeup_recommendations/nose_contour">Live Nose Contour Guides</NextLink>
                        </li>{" "}
                        <li class="flex items-center gap-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24" className="w-5 h-5 mr-2 text-amber-500 shrink-0"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z"/></svg>
                          <NextLink href="/makeup_recommendations/blush_placement"> Blush Placement
                          {/* <span className="text-xs">Free in Beta</span> */}
                          <span className="text-xs relative inline-block px-1 py-0.5 ml-2 font-semibold text-stone-900 leading-tight">
                            <span aria-hidden className="absolute inset-0 bg-stone-200 opacity-50 rounded-lg"></span>
                            <span className="relative">Free in Beta</span>
                          </span>
                          </NextLink>
                        </li>{" "}                        
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="mt-4 hidden md:block w-2/5 xl:w-3/5 mt-0">
           {/* <Image className="w-full max-w-[500px] mx-auto dark:hidden" src={HeroGraphicLight} alt="StyleSense.io - AI Face Analysis and Personalized Makeup Recommendations Tailored to Your Unique Features"/>
           <Image className="hidden w-full max-w-[500px] mx-auto dark:block" src={HeroGraphicDark} alt="StyleSense.io - AI Face Analysis and Personalized Makeup Recommendations Tailored to Your Unique Features"/> */}
            <div class="aspect-[878/1048] max-w-[500px] mx-auto">
            <Image
              className="w-full dark:hidden"
              src={HeroGraphicLight}
              alt="StyleSense.io - AI Face Analysis and Personalized Makeup Recommendations"
              width={878}  // Replace with actual image width
              height={1048} // Replace with actual image height
              // priority={true} // Optional: If you want to preload this image
            />
            <Image
              className="hidden w-full dark:block"
              src={HeroGraphicDark}
              alt="StyleSense.io - AI Face Analysis and Personalized Makeup Recommendations"
              width={878}  // Replace with actual image width
              height={1048} // Replace with actual image height
              // priority={true} // Optional: If you want to preload this image
            />           
            </div>
          </div>          
        </div>
      </section>
      <section class="h-screen/2 rounded bg-black p-4 block md:hidden">
        <div class="flex h-screen/2 flex-col items-center justify-center text-center">
          <h1
            id="mobile-convert-2-v3"
            class="text-3xl font-bold tracking-tighter text-white sm:text-5xl w-3/4 mt-4"
          >
            Say hello to your new{" "}
            <span className="text-transparent bg-clip-text bg-rainbow dark:bg-rainbowdark">
              signature style
            </span>
            .
          </h1>

          <h2 class="font-medium text-md mt-4 text-white/70 w-[85%]">
            The #1 AI-powered personal stylist and makeup guru
          </h2>
          <div class="mt-8 flex flex-col md:flex-row items-center justify-start space-y-2 md:space-y-0 md:space-x-2 w-full">
            {/* <a href="/app" class="w-full md:w-auto">
                <button data-v-fac1f164="" aria-label="Primary Button" type="button" class="flex content-center items-center justify-center text-center px-6 py-3 border border-transparent text-base font-bold rounded-md shadow-sm text-white gradient-bg hover:bg-gray-900 hover:border-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-200 block w-full md:w-auto shrink-0">
                  <span data-v-fac1f164="">Create your pictures now</span> 
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 ml-1.5" data-v-fac1f164="">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
                  </svg>
                </button>
              </a>  */}
            <NextLink
              href="/face_analyzer"
              className={`flex content-center items-center justify-center text-center px-6 py-3 border border-white/20 text-base font-medium rounded-md shadow-sm text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-200 flex-shrink-0 bg-[#2A2A2A] block w-full md:w-auto border-white bg-rainbow dark:bg-rainbowdark text-white px-4`}
            >
              Analyze My Face{" "}
              <span className={`${inter.className} ml-2`}>-&gt;</span>
            </NextLink>
            <NextLink
              href="/makeup_recommendations/face_contour"
              className={`flex content-center items-center justify-center text-center px-6 py-3 border border-white/20 text-base font-medium rounded-md shadow-sm text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-200 flex-shrink-0 bg-[#2A2A2A] block w-full md:w-auto border-white bg-rainbowdark dark:bg-rainbow text-white px-4`}
            >
              Personalize My Makeup{" "}
              <span className={`${inter.className} ml-2`}>-&gt;</span>
            </NextLink>            
            <section class="py-2 mt-5">
                <div class="">
                  <div class="flex flex-col items-left justify-left gap-8 md:flex-row lg:gap-16">
                    <div>
                      <h3 class="text-lg font-bold text-white">
                        Free features include
                      </h3>{" "}
                      <ul class="mt-4 space-y-3 text-white">
                        <li class="flex items-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-5 w-5 text-emerald-600"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                          <NextLink href="/face_analyzer">AI Face Shape Analyzer</NextLink>
                        </li>{" "}
                        <li class="flex items-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-5 w-5 text-emerald-600"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                          <NextLink href="/makeup_recommendations/face_contour">Live Face Contour Guides</NextLink>
                        </li>
                        <li class="flex items-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-5 w-5 text-emerald-600"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                          <NextLink href="/makeup_recommendations/eyeliner">Live Eyeliner Guides</NextLink>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <h3 class="text-lg font-bold text-white">
                        Advanced features include
                      </h3>{" "}
                      <ul class="mt-4 space-y-3 text-white">
                        <li class="flex items-center gap-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24" className="w-5 h-5 mr-2 text-amber-500 shrink-0"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z"/></svg>
                          <NextLink href="/face_analyzer/eye_shape">AI Eye Shape Analyzer</NextLink>
                        </li>{" "}
                        <li class="flex items-center gap-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24" className="w-5 h-5 mr-2 text-amber-500 shrink-0"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z"/></svg>
                          <NextLink href="/makeup_recommendations/nose_contour">Live Nose Contour Guides</NextLink>
                        </li>{" "}
                        <li class="flex items-center gap-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24" className="w-5 h-5 mr-2 text-amber-500 shrink-0"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z"/></svg>
                          <NextLink href="/makeup_recommendations/blush_placement">Live Blush Placement Guides
                          <span className="text-xs relative inline-block px-1 py-0.5 ml-2 font-semibold text-stone-200 leading-tight">
                            <span aria-hidden className="absolute inset-0 bg-stone-500 opacity-50 rounded-lg"></span>
                            <span className="relative">Free in Beta</span>
                          </span>                          
                          </NextLink>
                        </li>{" "}                        
                      </ul>
                    </div>
                  </div>
                </div>
              </section>            
          </div>
        </div>
      </section>
      <section data-v-7fa77991="" class="py-12 sm:py-16 lg:py-16 xl:py-16">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div class="max-w-3xl mx-auto text-center mb-4">
              <h2 class="mt-6 text-3xl font-medium tracking-tight text-gray-900 dark:text-gray-50 sm:text-4xl lg:mt-8 lg:text-5xl">
                Frequently asked questions
              </h2>{" "}
              <p class="mt-4 mb-8 text-base font-normal leading-7 text-gray-600 dark:text-gray-200 lg:text-lg lg:mt-6 lg:leading-8">
                We&apos;ve compiled a list of the most common questions we get asked.
                If you have any other questions, please feel free to reach out
                to us in the{" "}
                <NextLink href="/help" className="underline">
                  Help Center
                </NextLink>
                .
              </p>
            </div>{" "}
            <div className="dark:bg-white/50 dark:p-3 dark:rounded">
              <FaqBlock />
            </div>
          </div>
        </div>
      </section>
      <div className={styles.grid}>
        <NextLink href="/face_analyzer" className={styles.card}>
          <h2 className={inter.className}>
            Face Analyzer <span>-&gt;</span>
          </h2>
          <p className={inter.className}>
            Identify your face shape and other facial features.
          </p>
        </NextLink>

        <NextLink href="/makeup_recommendations/face_contour" className={styles.card}>
          <h2 className={inter.className}>
            {/* <div className="badge badge-neutral text-white badge-md mx-2 move-right">
              NEW
            </div> */}
            Makeup Recommendations <span>-&gt;</span>
          </h2>
          <p className={inter.className}>
            Learn how to best accentuate your facial features.
          </p>
        </NextLink>

        <NextLink href="/skintone_analyzer" className={styles.card}>
          <h2 className={inter.className}>
            Skintone Analyzer <span>-&gt;</span>
          </h2>
          <p className={inter.className}>In beta</p>
        </NextLink>
      </div>

      <Footer />
    </main>
    </>
  );
}